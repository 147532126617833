import React, { useEffect, useState, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import socketIO from 'socket.io-client';
import toast, { Toaster } from "react-hot-toast";

import signUpService from './services/signUpService'
import { setUser } from './reducers/userReducer'
import { getProfileData } from './reducers/profileReducer'
import { getUserNotifications } from './reducers/userNotificationsReducer'
import { getUserLists } from './reducers/userListsReducer'
import { changeOnlineUsers } from './reducers/onlineUsersReducer'
import { firebaseConfig } from "./utils/firebase";

import NavBar from './components/navbar/Navbar'
import Loader from './components/Loader'
import RedirectPage from './components/RedirectPage'

import "./css/App.css"

// Lazy loaded components
const Login = React.lazy(() => import('./components/Login'))
const Signup = React.lazy(() => import('./components/Signup'))
const Profile = React.lazy(() => import('./components/Profile'))
const ProfileSettings = React.lazy(() => import('./components/profile/ProfileSettings'))
const Browsing = React.lazy(() => import('./components/Browsing'))
const ConfirmMail = React.lazy(() => import('./components/login/ConfirmMail'))
const Chat = React.lazy(() => import('./components/chat/Chat'))
const ResetPassword = React.lazy(() => import('./components/login/ResetPassword'))
const ChangePassword = React.lazy(() => import('./components/profile/ChangePassword'))
const Logout = React.lazy(() => import('./components/Logout'))
const DeleteUser = React.lazy(() => import('./components/profile/DeleteUser'))
const UserProfile = React.lazy(() => import('./components/Userprofile'))
const PathNotExists = React.lazy(() => import('./components/PathNotExists'))
const ScheduleMeeting = React.lazy(() => import("./components/ScheduleMeeting"))
const SuperUser = React.lazy(() => import("./components/SuperUser"))
const Pricing = React.lazy(() => import("./components/Pricings"))
const ProfileCompletion = React.lazy(() => import("./components/ProfileCompletion"))

const App = () => {
	const [socket, setSocket] = useState(null)
	const [socketConnected, setSocketConnected] = useState(false)
	const dispatch = useDispatch()
	const user = useSelector(state => state.user)
	const app = initializeApp(firebaseConfig);
	const analytics = getAnalytics(app);

	useEffect(() => {
		const newSocket = socketIO('https://app.podzay.com/', {
		// const newSocket = socketIO('http://localhost:3001/', {
			withCredentials: true,
		});
		setSocket(newSocket);

		return () => {
			newSocket.disconnect();
		};
	}, []);

	useEffect(() => {
		if (!socket) return

		socket.on('connect', () => {
			setSocketConnected(true)
		})
		socket.on('newUserResponse', (data) => {
			dispatch(changeOnlineUsers(data))
		})
	}, [socket, dispatch, user])

	useEffect(() => {
		if (!socket) return;
		socket.on('new_notification', (data) => {
			toast.success(`${data?.text || 'New Message'}`, {
				style: {
					border: '1px solid rgba(167, 41, 232, 0.15)',
					color: '#070197',
					fontSize: 13,
					boxShadow: '0px 10px 30px rgba(0,0,0,0.1)'
				},
				iconTheme: {
					primary: '#070197',
					secondary: '#ffffff',
				},
			});
		});
		return () => socket.off('new_notification');
	}, [socket]);

	useEffect(() => {
		dispatch(getProfileData())
		dispatch(getUserLists())
		dispatch(getUserNotifications())
		signUpService
			.getSessionUser()
			.then(result => {
				dispatch(setUser(result))
			})
	}, [dispatch])

	useEffect(() => {
		if (user && socketConnected) {
			if (user.name && socket.id) {
				socket.emit("newUser", { name: user.name, id: user.id, socketID: socket.id })
				socket.emit("join_notification", { id: user.id })
			}
		}
	}, [user, socket, socketConnected])

	if (!socketConnected) return <Loader text="Waiting for socket..." />

	return (
		<div className="app_main_content">
			<Router>
				<RedirectPage />
				<Toaster
					position="top-center"
					reverseOrder={false}
				/>
				<NavBar socket={socket} />
				<div style={{ maxWidth: '1360px', margin: '4em auto' }}>
					<Suspense fallback={<Loader text="Loading..." />}>
						<Routes>
							<Route path="/" element={<Browsing />} />
							<Route path="/login" element={<Login socket={socket} />} />
							<Route path="/login/resetpassword" element={<ResetPassword />} />
							<Route path="/resetpassword/:user/:code" element={<ResetPassword />} />
							<Route path="/signup" element={<Signup socket={socket} />} />
							<Route path="/profile-completion" element={<ProfileCompletion />} />
							<Route path="/settings" element={<ProfileSettings />} />
							<Route path="/changepassword" element={<ChangePassword />} />
							<Route path="/confirm/:user/:code" element={<ConfirmMail />} />
							<Route path="/profile" element={<Profile />} />
							<Route path="/profile/:id" element={<UserProfile />} />
							<Route path="/browsing" element={<Browsing />} />
							<Route path="/super-user" element={<SuperUser />} />
							<Route path="/schedule" element={<ScheduleMeeting />} />
							<Route path="/chat" element={<Chat socket={socket} />} />
							<Route path="/pricing" element={<Pricing />} />
							<Route path="/chat/:id" element={<Chat socket={socket} />} />
							<Route path="/logout" element={<Logout socket={socket} />} />
							<Route path="/deleteuser" element={<DeleteUser />} />
							<Route path='*' element={<PathNotExists />} />
						</Routes>
					</Suspense>
				</div>
			</Router>
		</div>
	)
}

export default App
